import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";

import { history } from "_helpers";
import { Nav, Alert, PrivateRoute } from "_components";
import { Home } from "home";
import { AccountLayout } from "account";
import { UsersLayout } from "users";
import { ResponsesLayout, Response, DemoResponse } from "responses";
import "App.css";

export { App };

function App() {
  // init custom history object to allow navigation from
  // anywhere in the react app (inside or outside components)
  history.navigate = useNavigate();
  history.location = useLocation();

  return (
    <div className="App app-container">
      <Nav />
      <Alert />
      <div className="container pt-4 pb-4">
        <Routes>
          {/* public */}
          <Route path="account/*" element={<AccountLayout />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/" element={<Home />} />
          <Route path="response/demo" element={<DemoResponse />} />

          {/* private */}
          <Route element={<PrivateRoute />}>
            <Route path="users/*" element={<UsersLayout />} />
            <Route path="responses/*" element={<ResponsesLayout />} />
            <Route path="responses/:id" element={<Response />} />
            {/* Add a catch-all route to render App for all routes */}
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </div>
    </div>
  );
}
