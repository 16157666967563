import React, { useEffect, useState } from "react";
import MUIRichTextEditor from "mui-rte";
import { history } from "_helpers";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { tableRowClasses } from "@mui/material/TableRow";
import { Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Theme, styled } from "@mui/material/styles";
import { Editor, EditorState, convertToRaw, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { responsesActions, userActions } from "_store";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Modal,
  Box,
  TextField,
} from "@mui/material";

const HomeImage = styled("img")({
  width: "200px",
  height: "auto",
});

export { ResponsesList };

function ResponsesList() {
  const pts = [
    "c6d7cb0f-c3f4-4127-9724-fd39885b85ab",
    "phystherapist",
    "325b09bc-fade-4fbe-947a-81946b521a52",
    "da3491b8-97ce-4417-bed0-26f3c6ec8128",
    "9b5673a6-852c-46c6-afe3-82c5908cd5a3",
  ];

  const incompleteResponses = useSelector((x) =>
    x.responses.responsesList?.value?.filter((r) => r.isComplete === false)
  );
  const completeResponses = useSelector((x) =>
    x.responses.responsesList?.value?.filter((r) => r.isComplete === true)
  );

  const auth = useSelector((x) => x.auth.value);
  const dispatch = useDispatch();

  const isPt = pts.includes(auth.id);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [timeframe, setTimeframe] = useState("");
  const [url, setUrl] = useState("");
  const [condition, setCondition] = useState("");

  const BlackButton = styled(Button)({
    backgroundColor: "#0a9396",
    color: "white",
    "&:hover": {
      backgroundColor: "#005f73",
    },
    marginTop: "20px",
  });

  const ellipsisStyle = {
    whiteSpace: "wrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "30%",
    maxWidth: "350px",
    fontSize: "10px",
    padding: "5px",
  };

  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );

  const myTheme = createTheme({
    // Set up your custom MUI theme here
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(responsesActions.getAll(auth.id));
      setLoading(false);
    };

    fetchData();
  }, [dispatch, auth.id]);

  const openModal = (response) => {
    setIsModalOpen(true);
    setResponse(response);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setResponse(null);
  };

  const handleSubmit = () => {
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    dispatch(
      responsesActions.addExpertResponse({
        userId: response.userId,
        surveyId: response.responseId,
        response: JSON.stringify(rawContentState),
        timeFrame: timeframe,
        url: url,
        condition: condition,
      })
    );
    closeModal();
  };

  const handleView = (response) => {
    history.navigate(`responses/${response.responseId}`);
  };

  const externalURL = `https://9sfjxktb5mg.typeform.com/to/qYMUi8gu#user_id=${auth?.id}&user_name=${auth?.userName}`;

  const redirectToExternalURL = () => {
    window.location.href = externalURL;
  };

  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item md="16">
          <h1>Welcome Back, you handsome young man!</h1>
        </Grid>
        <Grid item ms="12" alignItems="flex-end">
          <BlackButton
            style={{ width: "10em", marginTop: "1em" }}
            variant="contained"
            onClick={redirectToExternalURL}
          >
            <span style={{ fontSize: "12px" }}>New Assessment</span>
          </BlackButton>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ marginTop: "25px" }}>
        <h4>Completed</h4>
        <TableContainer component={Paper} style={{ overflowX: "auto" }}>
          {loading ? (
            <CircularProgress />
          ) : completeResponses && completeResponses.length > 0 ? (
            <Table style={{ tableLayout: "fixed" }}>
              <TableHead>
                {isPt ? (
                  <TableRow style={{ height: "20px" }}>
                    <TableCell
                      style={{
                        width: "20%",
                        fontWeight: "bold",
                        height: "20px",
                      }}
                    >
                      User
                    </TableCell>
                    <TableCell
                      style={{
                        width: "50%",
                        fontWeight: "bold",
                        height: "20px",
                      }}
                    >
                      Problem Description
                    </TableCell>
                    <TableCell
                      style={{
                        width: "20%",
                        fontWeight: "bold",
                        height: "20px",
                      }}
                    >
                      Submitted
                    </TableCell>
                    <TableCell
                      style={{
                        width: "10%",
                        fontWeight: "bold",
                        height: "20px",
                      }}
                    ></TableCell>
                  </TableRow>
                ) : (
                  <TableRow
                    style={{ height: "20px", padding: "5px", width: "100%" }}
                  >
                    <TableCell
                      style={{
                        width: "35%",
                        padding: "5px",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      Problem{" "}
                    </TableCell>
                    <TableCell
                      style={{
                        width: "20%",
                        padding: "5px",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    >
                      Submitted
                    </TableCell>
                    <TableCell
                      style={{
                        width: "20%",
                        padding: "5px",
                        fontWeight: "bold",
                        fontSize: "12px",
                      }}
                    ></TableCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {completeResponses?.map((response) => (
                  <TableRow key={response.id} style={{ maxHeight: "20px" }}>
                    {isPt && (
                      <TableCell style={{ maxHeight: "20px" }}>
                        {response.userName}
                      </TableCell>
                    )}
                    <TableCell style={ellipsisStyle}>
                      {response.description}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: "11px", width: "15%", padding: "5px" }}
                    >
                      {new Date(response.submitted).toLocaleDateString()}
                    </TableCell>
                    <TableCell
                      style={{
                        maxHeight: "20px",
                        width: "15%",
                        whiteSpace: "nowrap",
                        padding: "5px",
                      }}
                    >
                      {isPt && (
                        <Button
                          onClick={() => openModal(response)}
                          variant="outlined"
                          color="secondary"
                        >
                          Respond
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                          handleView(response);
                        }}
                      >
                        View
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
                {completeResponses?.loading && (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          ) : (
            <Table style={{ tableLayout: "auto" }}>
              <TableBody>
                <TableRow>
                  <TableCell>No completed responses</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <div style={{ paddingTop: "3vh" }}>
          <h4>Pending</h4>
          <TableContainer component={Paper} style={{ overflowX: "auto" }}>
            {loading ? (
              <CircularProgress />
            ) : incompleteResponses && incompleteResponses.length > 0 ? (
              <Table style={{ tableLayout: "fixed" }}>
                <TableHead>
                  {isPt ? (
                    <TableRow style={{ height: "10px" }}>
                      <TableCell style={{ width: "20%", fontWeight: "bold" }}>
                        User
                      </TableCell>
                      <TableCell style={{ width: "50%", fontWeight: "bold" }}>
                        Problem Description
                      </TableCell>
                      <TableCell style={{ width: "20%", fontWeight: "bold" }}>
                        Submitted
                      </TableCell>
                      <TableCell
                        style={{ width: "10%", fontWeight: "bold" }}
                      ></TableCell>
                    </TableRow>
                  ) : (
                    <TableRow style={{ width: "100%" }}>
                      <TableCell style={{ width: "50%", fontWeight: "bold" }}>
                        Problem Description
                      </TableCell>
                      <TableCell style={{ width: "20%", fontWeight: "bold" }}>
                        Submitted
                      </TableCell>
                      <TableCell
                        style={{ width: "10%", fontWeight: "bold" }}
                      ></TableCell>
                    </TableRow>
                  )}
                </TableHead>
                <TableBody>
                  {incompleteResponses?.map((response) => (
                    <TableRow key={response.id} style={{ height: "10px" }}>
                      {isPt && <TableCell>{response.userName}</TableCell>}
                      <TableCell style={ellipsisStyle}>
                        {response.description}
                      </TableCell>
                      <TableCell>
                        {new Date(response.submitted).toLocaleDateString()}
                      </TableCell>
                      <TableCell style={{ whiteSpace: "nowrap" }}>
                        {isPt && (
                          <Button
                            onClick={() => openModal(response)}
                            variant="outlined"
                            color="secondary"
                          >
                            Respond
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                  {incompleteResponses?.loading && (
                    <TableRow>
                      <TableCell colSpan={4} align="center">
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            ) : (
              <Table style={{ tableLayout: "auto" }}>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ color: "rgba(0, 0, 0, 0.5)" }}>
                      No pending responses...
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </div>
      </Grid>
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "75%",
            bgcolor: "background.paper",
            boxShadow: 24,
            border: "2px",
            display: "flex",
            flexDirection: "column",
            height: "50%",
            overflowY: "auto",
            padding: "15px",
          }}
        >
          <ThemeProvider theme={myTheme}>
            {/* Textbox for Timeframe */}
            <TextField
              label="Recovery Time"
              variant="outlined"
              margin="normal"
              fullWidth
              value={timeframe}
              onChange={(e) => setTimeframe(e.target.value)}
            />

            {/* Textbox for URL */}
            <TextField
              label="Exercise Link"
              variant="outlined"
              margin="normal"
              fullWidth
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />

            {/* Textbox for Condition */}
            <TextField
              label="Buest Guess Condition"
              variant="outlined"
              margin="normal"
              fullWidth
              value={condition}
              onChange={(e) => setCondition(e.target.value)}
            />

            {/* MUIRichTextEditor for additional text input */}
            <MUIRichTextEditor
              label="Rationale"
              onChange={setEditorState}
              inlineToolbar={true}
            />
          </ThemeProvider>

          {/* Submit Button */}
          <div style={{ marginTop: "auto" }}>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              Submit
            </Button>
          </div>
        </Box>
      </Modal>
    </Grid>
  );
}

export default ResponsesList;
