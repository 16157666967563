import { Routes, Route } from 'react-router-dom';
import { ResponsesList } from './responselists/ResponsesList';
import { useSelector } from 'react-redux';
import {UserResponseList} from './responselists/UserResponseList';

export { ResponsesLayout };

function ResponsesLayout() {
    const pts = ["c6d7cb0f-c3f4-4127-9724-fd39885b85ab", "phystherapist", "325b09bc-fade-4fbe-947a-81946b521a52",
        "da3491b8-97ce-4417-bed0-26f3c6ec8128", "9b5673a6-852c-46c6-afe3-82c5908cd5a3"];

    const auth = useSelector((x) => x.auth.value);
    const isPt = pts.includes(auth.id);

    return (
        <>
         {isPt ? (
            <div className="p-3">
                <Routes>
                    <Route index element={<ResponsesList />} />
                </Routes>
            </div>
            ) :        
            (
            <div className="p-1">
                <Routes>
                    <Route index element={<UserResponseList />} />
                </Routes>
            </div>
            )
        }
        </>
    );
}
