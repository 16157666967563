// ResponseTable.jsx
import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
} from '@mui/material';

const ellipsisStyle = {
  whiteSpace: 'wrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  width: '30%',
  maxWidth: '350px',
  fontSize: '11px',
  padding:'10px'
};

export { ResponseTable };

function ResponseTable({ responses, loading, isComplete, handleView }) {
  return (
    <TableContainer component={Paper} style={{ overflowX: 'auto', padding:'0px' }}>
      {loading ? (
        <CircularProgress />
      ) : (
        (responses && responses.length > 0) ? (
          <Table style={{ tableLayout: 'fixed' }}>
            <TableHead>
              <TableRow style={{ height: '20px', padding: '5px', width: '100%' }}>
                <TableCell style={{ width: '35%', padding: '5px', fontWeight: 'bold', fontSize: '12px' }}>Problem</TableCell>
                <TableCell style={{ width: '20%', padding: '5px', fontWeight: 'bold', fontSize: '12px' }}>Submitted</TableCell>
                {isComplete && (<TableCell style={{ width: '20%', padding: '5px', fontWeight: 'bold', fontSize: '12px' }}></TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {responses?.map((response) => (
                <TableRow key={response.id} style={{ maxHeight: '20px' }}>
                  <TableCell style={ellipsisStyle}>{response.description}</TableCell>
                  <TableCell style={{ fontSize: '11px', width: '15%', padding: '5px' }}>{new Date(response.submitted).toLocaleDateString()}</TableCell>
                  {isComplete && (
  <TableCell style={{ maxHeight: '20px', width: '15%', whiteSpace: 'nowrap', padding: '5px', overflow: 'hidden' }}>
    <Button variant="contained" color="primary" size="small" onClick={() => { handleView(response) }}>
      View
    </Button>
  </TableCell>
)}
                </TableRow>
              ))}
              {responses?.loading && (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        ) : (
          <Table style={{ tableLayout: 'auto' }}>
            <TableBody>
              <TableRow>
                 <TableCell style={{ opacity: 0.5 }}>No responses...</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )
      )}
    </TableContainer>
  );
}

export default ResponseTable;
