import React from 'react';
import { Link } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { Theme, styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup'
import {Button, TextField, Paper} from '@mui/material';
import {makeStyles} from '@mui/styles';
import { authActions } from '_store';
import './account.css';

import { history } from '_helpers';
import { userActions, alertActions } from '_store';

const validationSchema = Yup.object().shape({
  username: Yup.string().required('Username is required'),
  email: Yup.string().required('Email is required'),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Password confirmation is required'),
});

const BlackButton = styled(Button)({
  backgroundColor: '#0a9396',
  width: '40%',
  color: 'white',
  '&:hover': {
    backgroundColor: '#005f73',
  },
});

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginBottom: '10px',
    marginTop: '7px',
    width: '16em',
  }
}
));

function Register() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { control, handleSubmit, formState } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { errors, isSubmitting } = formState;

  const onSubmit = async (data) => {
    dispatch(alertActions.clear());
    const email = data.email;
    const password = data.password;
    try {
      await dispatch(userActions.register(data)).unwrap();

      return dispatch(authActions.login({email, password })).then(() => {
            history.navigate('./');
        });
    } catch (error) {
      
      dispatch(alertActions.error("Email/Username is taken"));
    }
  };

  return (
   <>
  <form onSubmit={handleSubmit(onSubmit)} className={classes.form} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Controller
      name="email"
      control={control}
      render={({ field }) => (
        <TextField
          className={classes.input}
          variant="standard"
          label="Email"
          type="text"
          error={!!errors.email}
          helperText={errors.email?.message}
          {...field}
        />
      )}
    />

    <Controller
      name="username"
      className="input"
      control={control}
      render={({ field }) => (
        <TextField
          className={classes.input}
          variant="standard"
          label="Username"
          type="text"
          error={!!errors.username}
          helperText={errors.username?.message}
          {...field}
        />
      )}
    />

    <Controller
      name="password"
      control={control}
      className="input"
      render={({ field }) => (
        <TextField
          label="Password"
          type="password"
          className={classes.input}
          variant="standard"
          error={!!errors.password}
          helperText={errors.password?.message}
          {...field}
        />
      )}
    />

    <Controller
      name="passwordConfirmation"
      className="input"
      control={control}
      render={({ field }) => (
        <TextField
          className={classes.input}
          variant="standard"
          label="Confirm Password"
          type="password"
          error={!!errors.passwordConfirmation}
          helperText={errors.passwordConfirmation?.message}
          {...field}
        />
      )}
    />

    <BlackButton
      type="submit"
      variant="contained"
      disabled={isSubmitting}
      style={{ width: '30%' }}
    >
      {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
      Submit
    </BlackButton>

    <Button  variant="text"  style={{ marginTop: '5px' }} component={Link} to="../../home" color="primary">
         Cancel
    </Button>
  </form>
</>

  );
}

export { Register };
