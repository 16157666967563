import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { Theme, styled } from '@mui/material/styles';
import { history } from '_helpers';
import './Home.css';
import { Grid } from '@mui/material';
import { Login, Register } from 'account'; // Import your Register component

const centeredGridStyles = {
  minHeight: 'calc(85vh - 110px)', // Set the minimum height of the container to full viewport height
  display: 'flex',
  flexDirection: 'column', // Stack items vertically
  justifyContent: 'center',
  alignItems: 'center',
};

const BlackButton = styled(Button)({
  backgroundColor: '#0a9396',
  color: 'white',
  '&:hover': {
    backgroundColor: '#005f73',
  },
  marginTop: '20px',
});

const TransparentButton = styled(Button)({
  backgroundColor: 'transparent',
  color: '#0a9396',
  border: '2px solid #005f73',
  '&:hover': {
    backgroundColor: '#005f73',
    color: 'white',
  },
  marginTop: '20px',
});

const HomeImage = styled('img')({
  width: '250px', // Set the width of your image as per your requirement
  height: 'auto',
});

function Home() {
  const auth = useSelector((x) => x.auth.value);
  const [showRegister, setShowRegister] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const externalURL = `https://9sfjxktb5mg.typeform.com/to/qYMUi8gu#user_id=${auth?.id}&user_name=${auth?.userName}`;

  const redirectToExternalURL = () => {
    window.location.href = externalURL;
  };

  const navigateToRespose = () =>{
      history.navigate('responses');
  }

  const isLoggedIn = !!auth;

  return (
    <Grid container style={centeredGridStyles} spacing={2}>
      <Grid item xs={12} align="center">
        <HomeImage src="SanoLarge.JPG" alt="SANO PT Logo" />
      </Grid>
      <Grid item xs={12} align="center">
        {isLoggedIn ? (
          <>
          <BlackButton style={{ marginRight: '5px', width:'8em'   }}  variant="contained" onClick={redirectToExternalURL}>
            Assessment
          </BlackButton>
          <TransparentButton style={{ width: '8em' }}variant="contained" onClick={navigateToRespose}>
            Responses
          </TransparentButton>
          </>
        ) : (
          showLogin ? (
            <Login /> // Render your Register component
          ) : showRegister ? (
            <Register /> // Render your Register component
          ) : (
            <>
              <TransparentButton style={{ marginRight: '5px' }} variant="contained" onClick={() => setShowLogin(true)}>
                Login
              </TransparentButton>
              <BlackButton style={{ width: '7em' }} variant="contained" onClick={() => setShowRegister(true)}>
                Register
              </BlackButton>
            </>
          )
        )}
      </Grid>
    </Grid>
  );
}

export { Home };
