// Import necessary components and styles
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { responsesActions } from "_store";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import { CircularProgress, Button } from "@mui/material";
import { stateToHTML } from "draft-js-export-html";
import { Editor, EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Theme, styled } from "@mui/material/styles";
import AccessAlarmsIcon from "@mui/icons-material/AccessAlarms";
import { Link } from "react-router-dom";
import "./response.css";

// Add this style in your CSS file or in the component
const styles = {
  avatar: {
    width: 60,
    height: 60,
    borderRadius: "50%",
  },
};

const TransparentButton = styled(Button)({
  backgroundColor: "transparent",
  color: "rgb(27, 126, 93)",
  border: "2px solid rgb(27, 126, 93)",
  "&:hover": {
    backgroundColor: "#005f73",
    color: "white",
    border: "2px solid #febc03",
  },
});

function Response() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const surveyResponses = useSelector((x) => x.responses.responsesList);
  const auth = useSelector((x) => x.auth.value);

  useEffect(() => {
    if(id !== "demo" && !auth.id)
    dispatch(responsesActions.getAll(auth.id));

    let apiId = id === "demo" ? "z64cbmlio59pxx7z64cb86tpoxaok6kr" : id;

    dispatch(responsesActions.getExpertResponse(apiId))
      .then((apiResponse) => {
        setResponse(apiResponse);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching response:", error);
        setLoading(false);
      });
  }, [dispatch, id]);

  const renderHtmlFromDraft = (rawContentState) => {
    try {
      const contentState = convertFromRaw(JSON.parse(rawContentState));
      const html = stateToHTML(contentState);
      return { __html: html };
    } catch (error) {
      console.error("Error rendering Draft.js content to HTML:", error);
      return null;
    }
  };

  const usernameLoaded = surveyResponses?.value?.find(
    (r) => r.responseId === id
  )?.userName;

  return (
    <>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=EW+Nexa:400,600,700&display=swap"
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {loading ? (
            <CircularProgress />
          ) : response.payload ? (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} style={{ paddingBottom: "0px" }}>
                <Card variant="outlined" style={{ background: "#f5faf7" }}>
                  <CardHeader
                    style={{
                      paddingBottom: "0px",
                      fontSize: "large",
                      fontWeight: "600",
                      fontFamily:
                        "EW Nexa, Helvetica Neue, Helvetica, Arial, sans-serif",
                      color: "rgb(0, 115, 119)",
                    }}
                    title="Reviewed By"
                    titleTypographyProps={{ variant: "title3" }}
                  />
                  <CardContent
                    style={{
                      paddingBottom: "0px",
                      display: "flex",
                      alignItems: "center",
                      padding: "2vh",
                    }}
                  >
                    <Avatar
                      alt="User Avatar"
                      src="/chrisPhoto.png"
                      sx={styles.avatar}
                    />
                    <Typography
                      variant="body1"
                      style={{
                        marginLeft: "1em",
                        fontFamily:
                          "EW Nexa, Helvetica Neue, Helvetica, Arial, sans-serif",
                        fontWeight: "500",
                      }}
                    >
                      Chris Smith, <br /> Dr of Physical Therapy
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                style={{ height: "0px", margin: "0px", padding: "0px" }}
                item
                xs={0}
                sm={12}
              ></Grid>
              <Grid item xs={12} sm={6}>
                <Card
                  raised={true}
                  style={{
                    backgroundColor: "#ffffff",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <CardHeader
                    style={{
                      color: "rgb(27, 126, 93)",
                      fontSize: "large",
                      fontWeight: "600",
                      paddingBottom: "0px",
                      paddingTop: "0.5em",
                    }}
                    title="Best Guess"
                    titleTypographyProps={{ variant: "title3" }}
                  />
                  <CardContent
                    style={{
                      flex: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                      padding: "1vh",
                      marginLeft: "1em",
                      fontWeight: "600",
                    }}
                  >
                    <Avatar
                      alt="User Avatar"
                      src="/NeuralTension.png"
                      sx={{ width: 56, height: 56 }}
                    />
                    <span style={{ marginLeft: "1em", color: "black" }}>
                      {response.payload.condition}
                    </span>
                  </CardContent>
                </Card>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                }}
              >
                <Card
                  raised={true}
                  style={{ backgroundColor: "#ffffff", flexGrow: 1 }}
                >
                  <CardHeader
                    style={{
                      color: "rgb(27, 126, 93)",
                      fontSize: "large",
                      fontWeight: "600",
                      paddingBottom: "0px",
                      paddingTop: "0.5em",
                    }}
                    title="Exercises"
                    titleTypographyProps={{ variant: "title3" }}
                  />
                  <CardContent
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "left",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={6} sm={4}>
                        <Link
                          to={response.payload.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <TransparentButton
                            style={{ paddingLeft: "1em", fontWeight: "bold" }}
                            variant="contained"
                          >
                            My Exercises
                          </TransparentButton>
                        </Link>
                      </Grid>
                      <Grid item xs={1} sm={1} style={{ textAlign: "right" }}>
                        <AccessAlarmsIcon color="white" fontSize="medium" />
                      </Grid>

                      <Grid
                        item
                        xs={5}
                        sm={7}
                        style={{
                          fontSize: "medium",
                          paddingLeft: "0.5em",
                        }}
                      >
                        <span>
                          {`Est recovery time: `}
                          <b>{`${response.payload.timeFrame}`}</b>
                        </span>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card raised={true} style={{ backgroundColor: "#ffffff" }}>
                  <CardHeader
                    style={{
                      paddingBottom: "0px",
                      fontSize: "large",
                      color: "rgb(27, 126, 93)",
                      fontSize: "large",
                      fontWeight: "600",
                    }}
                    title="From your expert"
                    titleTypographyProps={{ variant: "title3" }}
                  />
                  <CardContent>
                    <div
                      dangerouslySetInnerHTML={renderHtmlFromDraft(
                        response.payload.response
                      )}
                    />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography variant="body1">No response data found.</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export { Response };
