import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { Theme, styled } from '@mui/material/styles';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import {history} from '_helpers'
import {Button, TextField, Paper} from '@mui/material';
import {makeStyles} from '@mui/styles';
import { authActions } from '_store';
const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required'),
        password: Yup.string().required('Password is required')
    });

// ... (your imports)

export { Login };

const BlackButton = styled(Button)({
  backgroundColor: '#0a9396',
  width: '40%',
  color: 'white',
  '&:hover': {
    backgroundColor: '#005f73',
  },
});

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginBottom: '10px',
    marginTop: '7px',
    width: '16em',
  },
}));

function Login() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [originalUrl, setOriginalUrl] = useState(null);
  const classes = useStyles();
  const { control, handleSubmit, formState } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { errors, isSubmitting } = formState;
  const [loginError, setLoginError] = useState(null);

  useEffect(() => {
    // Store the original URL when the component mounts
    setOriginalUrl(location.state?.from.pathname || '/');
  }, [location]);

  function onSubmit({ email, password }) {
    // Dispatch login action, and after a successful login, redirect to the original URL
    dispatch(authActions.login({ email, password }))
      .catch((error) => {
        console.log(error);
        setLoginError('Invalid email or password. Please try again.');
      });
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              className={classes.input}
              variant="standard"
              label="Email"
              type="text"
              error={!!errors.email}
              helperText={errors.email?.message}
              {...field}
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          className="input"
          render={({ field }) => (
            <TextField
              label="Password"
              type="password"
              className={classes.input}
              variant="standard"
              error={!!errors.password}
              helperText={errors.password?.message}
              {...field}
            />
          )}
        />
        <BlackButton disabled={isSubmitting} type="submit" variant="contained">
          {isSubmitting && <span className="spinner-border spinner-border-sm me-1"></span>}
          Submit
        </BlackButton>
        <Button variant="text" style={{ marginTop: '5px' }} component={Link} to="../login" color="primary">
          Cancel
        </Button>
        {loginError && (
          <div style={{ color: 'red', marginTop: '10px' }}>
            {loginError}
          </div>
        )}
      </form>
    </>
  );
}
