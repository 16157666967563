import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { authActions } from '_store';

export { Nav };

function Nav() {
    const auth = useSelector(x => x.auth.value);
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());

    // only show nav when logged in
    
    return (
        <nav style={{background:"black"}}className="navbar navbar-expand navbar-dark px-3">
            <NavLink to="/" className="nav-item nav-link">
              <img
                src="/SanoNav2.JPG"
                alt="Logo"
                style={{ maxHeight: '30px', maxWidth: '100%', paddingLeft: '0px' }}
            >
                </img>
                </NavLink>
            <div className="navbar-nav mr-auto">
                
                {/* Uncomment the line below if you have a "Users" link */}
                {/* {auth && <NavLink to="/users" className="nav-item nav-link">Users</NavLink>} */}
            </div>
            {auth && (
                <div className="navbar-nav">
                    <button onClick={logout} className="btn btn-link nav-item nav-link">Logout</button>
                </div>
            )}
        </nav>
    );
}
