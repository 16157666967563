import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { fetchWrapper } from '_helpers';

const name = 'responses';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, extraReducers });

// exports

export const responsesActions = { ...slice.actions, ...extraActions };
export const responsesReducer = slice.reducer;


function createInitialState() {
    return {
        responsesList: null
    }
}


function createExtraActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}`;

    return {
        getAll: getAll(),
        addExpertResponse: addExpertResponse(),
        getExpertResponse: getExpertResponse()
    };
    
    function getAll() {
        return createAsyncThunk(
            `${name}/getAll`,
            async (userId) => await fetchWrapper.get(`${baseUrl}/response?userId=${userId}`)
        );
    }

    function getExpertResponse() {
        return createAsyncThunk(
            `${name}/getExpertResponse`,
            async (surveyId) => await fetchWrapper.get(`${baseUrl}/expertresponse/?surveyId=${surveyId}`)
        );
    }

    function addExpertResponse() {
        return createAsyncThunk(
            `${name}/addExpertResponse`,
            async (expertResponse) => await fetchWrapper.post(`${baseUrl}/expertresponse`, expertResponse)
        );
    }
}

function createExtraReducers(){
    return (builder) => {
        getAll();
        
        function getAll() {
            var { pending, fulfilled, rejected } = extraActions.getAll;
            builder
                .addCase(pending, (state) => {
                    state.responsesList = { loading: true };
                })
                .addCase(fulfilled, (state, action) => {
                    state.responsesList = { value: action.payload };
                })
                .addCase(rejected, (state, action) => {
                    state.responsesList = { error: action.error };
                });
        }
    }
}