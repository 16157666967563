import React, { useEffect, useState } from 'react';
import MUIRichTextEditor from 'mui-rte';
import { history } from '_helpers';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import{ tableRowClasses } from "@mui/material/TableRow";
import { Grid } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { Theme, styled } from '@mui/material/styles';
import { Editor, convertToRaw, convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { responsesActions, userActions } from '_store';
import {ResponseTable} from './ResponseTable';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Modal,
  Box,
  TextField,
} from '@mui/material';

const HomeImage = styled('img')({
  width: '200px',
  height: 'auto',
});

export { UserResponseList };

function UserResponseList() {
  const pts = ["c6d7cb0f-c3f4-4127-9724-fd39885b85ab", "phystherapist", "325b09bc-fade-4fbe-947a-81946b521a52",
    "da3491b8-97ce-4417-bed0-26f3c6ec8128", "9b5673a6-852c-46c6-afe3-82c5908cd5a3"];

  const incompleteResponses = useSelector((x) => x.responses.responsesList?.value?.filter(r => r.isComplete === false));
  const completeResponses = useSelector((x) => x.responses.responsesList?.value?.filter(r => r.isComplete === true));

  const auth = useSelector((x) => x.auth.value);
  const dispatch = useDispatch();


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(true);

  const BlackButton = styled(Button)({
    backgroundColor: '#0a9396',
    color: 'white',
    '&:hover': {
      backgroundColor: '#005f73',
    },
    marginTop: '20px',
  });

  const myTheme = createTheme({
    // Set up your custom MUI theme here
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(responsesActions.getAll(auth.id));
      setLoading(false);
    };

    fetchData();
  }, [dispatch, auth.id]);

  const openModal = (response) => {
    setIsModalOpen(true);
    setResponse(response);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setResponse(null);
  };



  const handleView = (response) => {
    history.navigate(`responses/${response.responseId}`);
  };

  const externalURL = `https://9sfjxktb5mg.typeform.com/to/qYMUi8gu#user_id=${auth?.id}&user_name=${auth?.userName}`;

  const redirectToExternalURL = () => {
    window.location.href = externalURL;
  };

  return (
    <Grid container style={{padding: '0px'}}>
      <Grid item xs={6}>
        <h1>My Sano</h1>
      </Grid>
      <Grid item xs={12}>
        <BlackButton variant="contained" onClick={redirectToExternalURL}>
          <span style={{ fontSize: '12px' }}>New Assessment</span>
        </BlackButton>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '25px' }}>
        <h4>Completed...</h4>
        <ResponseTable responses={completeResponses} loading={loading} isComplete={true} handleView={handleView} />
      </Grid>
      <Grid item xs={12}>
        <div style={{ paddingTop: '3vh' }}>
          <h4>Pending...</h4>
          <ResponseTable responses={incompleteResponses} loading={loading} isComplete={false} handleView={handleView} />
        </div>
      </Grid>
    </Grid>
  );
}

export default UserResponseList;
